import { clusterApiUrl, Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, Transaction } from '@solana/web3.js';
import { createMint, getOrCreateAssociatedTokenAccount, mintTo, transfer, createTransferInstruction, TOKEN_PROGRAM_ID} from '@solana/spl-token';
import './App.css';
import coinLogo from './img/logo.gif';
import loading from './img/loading.gif';
import { useState, useEffect, useRef } from 'react';


function App() {

  const NodeUrl = "https://side-responsive-lambo.solana-mainnet.quiknode.pro/b64517cee74cc3c91d682146d9ea5ae7ee76315a/"
  const tokenMintAddress = "HU2qGM2v3RDH7DWAcWTXE15gfgwu66rvG9QhKGNTqNCu"
  const JackpotWalletAddress = "5V5ZLHDLPC154K5xNABw1GMBF5G8Sk4zd74wtWTh7Gaz"
  

  const [Provider, setProvider] = useState(false)
  const amountRef = useRef(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [minBet, setMinBet] = useState(0);
  const [gameInPlay, setgameInPlay] = useState(false)
  const [menuPage, setmenuPage] = useState(true)
  const [gameResult, setGameResult] = useState('')
  const isInitialMount = useRef(true);
  const [resultMessage, setResultMessage] = useState('')
  const [amountError, setAmountError] = useState('')
  const [applicationError, setApplicationError] = useState('')
  const [buttonPressed,setButtonPressed] = useState(false)
 
  const TransactionStatus = ({ txSignature }) => {
    const connection = new Connection(NodeUrl, 'confirmed')
    const [status, setStatus] = useState('pending');
    console.log("checkingstatus")
    useEffect(() => {
      let isMounted = true;
  
      const checkTransactionStatus = async () => {
        try {
          const status = await connection.getSignatureStatus(txSignature);
          if (!isMounted) return; // Ensure component is still mounted
  
          if (status && status.value && status.value.err) {
            setStatus('error');
            clearInterval(intervalId);
          } else if (status && status.value && status.value.confirmations === undefined) {
            setStatus('processing');
          } else {
            setStatus('confirmed');
            console.log("transactionconfirmed")
            clearInterval(intervalId); // Stop polling when transaction is confirmed
          }
        } catch (error) {
          console.error('Error fetching transaction status:', error);
          setStatus('error');
        }
      };
  
      const intervalId = setInterval(() => {
        checkTransactionStatus();
      }, 2000); // Poll every 3 seconds
  
      return () => {
        isMounted = false;
        clearInterval(intervalId); // Cleanup: clear interval on unmount or completion
      };
    }, [txSignature]);
  
    
  };
  const TokenPrice = async () => {
    
    
    try {
      const response = await fetch(`https://frontend-api.pump.fun/coins/${tokenMintAddress}`);
      
      const jsonData = await response.json();
      const marketCap = jsonData.usd_market_cap
      const price = (marketCap / 1_000_000_000);
      const minimum_bet = 0
      setMinBet(minimum_bet);
     
    } catch (error) {
      console.error('Error fetching token data:', error);
    }
    
  
     
    
  }
  //code to check if there is application error an make it dissapear after certain amount of time
  useEffect(() => {
    let timer;
    if (applicationError) {
      timer = setTimeout(() => {
        setApplicationError("") 
      }, 5000); // Change this value to the desired time in milliseconds
    }
  }, [applicationError]);

  const ApiRequest = async (signature,side) =>{
    var isWin = ''
    try {
      const response = await fetch('https://infinite-brushlands-75057-b987d4224ba8.herokuapp.com/get_result', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "signature":signature })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.status === 'win') {
        // Handle win scenario
        console.log('You won!');
        isWin=true
        // You can do additional things here, like updating the UI
      } else if (result.status === 'lose') {
        // Handle lose scenario
        console.log('You lost.');
        isWin=false
        // You can do additional things here, like updating the UI
      } else {
        // Handle other possible statuses or errors
        console.log('Unexpected status:', result.status);
        isWin=false
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
    if (isWin===true){
      setResultMessage("You Won");
      setGameResult(side)
    }
    else{
        setResultMessage("You Lost");
        if (side==="heads"){setGameResult('tails')}
        else{setGameResult('heads')}
      } 
  };
   
    
  

  const checkTokenBalance = async () => {
    try{
    // Connect to the Solana network
    const connection = new Connection(NodeUrl, 'confirmed')
    const publicKeyString = window.phantom.solana.publicKey.toString()
    
    // Define the public keys for the wallet and the token mint
    const walletPublicKey = new PublicKey(publicKeyString)
    const tokenMintPublicKey = new PublicKey(tokenMintAddress);
  
    // Get the associated token account for the wallet
    const tokenAccounts = await connection.getTokenAccountsByOwner(walletPublicKey, {
      mint: tokenMintPublicKey,
      programId: TOKEN_PROGRAM_ID,
    });
  
    if (tokenAccounts.value.length === 0) {
      setTokenBalance(0)
    }
    else {
      // Get the token balance from the first associated account
      const tokenAccount = tokenAccounts.value[0].pubkey;
      const tokenAccountInfo = await connection.getParsedAccountInfo(tokenAccount);
    
      // Extract the token balance
      const tokenBalance = tokenAccountInfo.value.data.parsed.info.tokenAmount.uiAmount;
    
      console.log(`Token balance: ${tokenBalance}`);
      setTokenBalance(tokenBalance);
    }
    }catch{
      setApplicationError("Error Checking Token Balance: Refresh Page. Contact Us On Twitter if this occurs again")
    }
    };
  //code to check if the wallet is connected
  useEffect( () => {
    
    const checkEagerConnection = async () => {
      try{
      TokenPrice()
      if ('phantom' in window) {
        const phantomProvider = window.phantom?.solana;

        if (phantomProvider?.isPhantom) {
          // Will either automatically connect to Phantom, or do nothing.
          await phantomProvider.connect({ onlyIfTrusted: true })
            .then(() => {
              setProvider(true)
              checkTokenBalance();
              // Handle successful eager connection
            })
            .catch(() => {
              // Do nothing if connection fails
            });
        }
      }
      }catch{
        setApplicationError("Error with using previous wallet approval, try refresh. Contact us on twitter for any other problems")
      }
    }
    if (isInitialMount.current) {
    isInitialMount.current = false;
    checkEagerConnection();
  }
  }, []);

  // function to connect wallet
  const connectWallet = async() => {
    try{
    //check if the phantom api is available in the browser
    if ('phantom' in window) {
      const provider = window.phantom?.solana;

      if (provider?.isPhantom) {
        
        const phantomConnection = await provider.connect()
        checkTokenBalance();
        setProvider(true)
      }
    };
  }
  catch (err){
    setApplicationError(err+"If this occurs again dm us on twitter")

  }
  }
    //code to send transaction
  const SendTranscation = async () => {
    const connection = new Connection(NodeUrl, 'confirmed')
    try{
    const amount = amountRef.current.value
    const SenderPublicKey = new PublicKey(window.phantom.solana.publicKey.toString())
    const RecieverPublicKey = new PublicKey(JackpotWalletAddress)
    const TokenMint = new PublicKey(tokenMintAddress)
    
    // Connect to cluster
  

    console.log("pressed")
    
    // Get the token account of the fromWallet address, and if it does not exist, create it
    const senderTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      SenderPublicKey,
      TokenMint,
      SenderPublicKey
    );
    const receiverTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        SenderPublicKey,
        TokenMint,
        RecieverPublicKey
      );
    // console.log(ToTokenAccount)
    console.log("yay")

    const transferInstruction = createTransferInstruction(
      senderTokenAccount.address,
      receiverTokenAccount.address,
      SenderPublicKey,
      amount*1000000,
      [],
      TOKEN_PROGRAM_ID
    );
    const transaction = new Transaction().add(transferInstruction);
    
    
    transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
    transaction.feePayer = SenderPublicKey;
    const { signature } = await window.phantom.solana.signAndSendTransaction(transaction);
    // await connection.getSignatureStatus(signature);
    return(signature)
  }catch{
    setButtonPressed(false)
    setApplicationError("Error Sending Transaction: Try again lad")
  }
  };

   
  // Common function to handle button clicks
  const gameResultGenerator = async (action) => {
    try{
    setButtonPressed(true)
    const amount = amountRef.current.value
    if (minBet<amount && amount<tokenBalance){
      setAmountError("")
      const signature = await SendTranscation()
      if (signature){
        if (action === 'heads') {
          setButtonPressed(false)
          setmenuPage(false)
          setgameInPlay(true)
          ApiRequest(signature,'heads')
          console.log('Heads Button Pressed');
          // Add your specific logic for heads button here
        } else if (action === 'tails') {
          setButtonPressed(false)//shows throbber between button press and phantom processing transaction
          setmenuPage(false)
          setgameInPlay(true)
          ApiRequest(signature,'tails')
          console.log('Tails button pressed');
          // Add your specific logic for tails button here
        }
      }
  }
  else{
    setAmountError("You don't have enough tokens for this bet or your bet is under minimum bet")
  }
  }catch{
    setButtonPressed(false)
    setApplicationError("Error: Refresh Page")
  }
  };

  
  const Gameui = () => {
    return (
    <>
      <div className="gif-container">
        <img src={coinLogo} className="gif" alt="Animation GIF" />
      </div>
      <form>
        <span className='field'>PUMPFLIP Balance: ${tokenBalance}</span>
        <span className='field'>Minimum Bet Amount: ${minBet}</span>
        <label className='label'>
          <input className="input" type="number" placeholder="Amount To Bet" ref={amountRef} />
        </label>
      </form>
      {amountError&&<h2>{amountError}</h2>}{buttonPressed && <img src={loading} alt="Loading" />}
      <div className="button-group">
        <button className="btn" onClick={() => gameResultGenerator('heads')}>Heads</button>
        <button className="btn" onClick={() => gameResultGenerator('tails')}>Tails</button>
      </div>
      </>
    )
  }
  
  const Walletui = () => {
    try{
    return (
    <>
      <div className="gif-container">
        <img src={coinLogo} className="gif" alt="Animation GIF" />
      </div>
      <div className='connect-wallet-container'>
        <button className="btn" onClick={connectWallet}>
          Connect Phantom Wallet
        </button>
      </div>
      </>
    )
  }
  catch{
    setApplicationError("Error: Couldnt load UI. Refresh Page. If this occurs again dm us on twitter")
  }
  }
  const playAgain = () =>{
    try{
    setmenuPage(true)
    setgameInPlay(false)
    }
    catch{
      setApplicationError("Error: Couldnt load UI. Refresh Page. If this occurs again dm us on twitter")

    }
  }
  const Resultsui = () => {
    try{
    return(
      <>
      {!gameResult && (
      <>
      <div className="gif-container">
        <img src={coinLogo} className="gif" alt="Animation GIF" />
      </div>
      <h2>Coin is being flipped in the mainframe</h2>
      </>
      )}
      {gameResult==="heads" && (
      <>
      <div className="gif-container">
        <img src={coinLogo} className="gif" alt="Animation GIF" />
      </div>
      <h2>{resultMessage}</h2>
      <div className="button-group">
        <button className="btn" onClick={() => playAgain()}>Play Again</button>
      </div>
      </>
      )}
      {gameResult==="tails" && (
      <>
      <div className="gif-container">
        <img src={coinLogo} className="gif" alt="Animation GIF" />
      </div>
      <h2>{resultMessage}</h2>
      <div className="button-group">
        <button className="btn" onClick={() => playAgain()}>Play Again</button>
      </div>
      </>
      )}
      </>
    )}
    catch{
      setApplicationError("Error: Couldnt load UI. Refresh Page. If this occurs again dm us on twitter")
    }
  }
  
  return (
    <div className="app-container">
      <header>
        <h1>PumpFlip</h1>
        {applicationError&&<h2>{applicationError}</h2>}
      </header>
      {menuPage && (
      !Provider ? <Walletui /> : <Gameui />
      )}
      {gameInPlay&& (
        <Resultsui/>
      )}
      <section className="placeholder-text">
        <h2>How It Works</h2>
        <p>Send your pump flip tokens to game, if the coin lands on your picked side you double your money. If it loses we burn your tokens, so you better have a wallet full cause either way you win when the marketcap goes up.</p>
      </section>
    </div>
  
  );
};

export default App;
